import React from "react"

const TwoLinksBar = () => (
  <section className="homeLinks">
    <div className="homeLinksFlex">
      <a href="/mutuelle-sante/" className="buttonMutuelle">Mutuelle santé</a>
      <a href="/mutuelle-prevoyance/" className="buttonPrevoyance">Prévoyance</a>
    </div>
  </section>
);

export default TwoLinksBar;
