import React, { Component} from 'react';
import PropTypes from 'prop-types';

class HomeHeaderGlobalFlexItem extends Component {
  render() {
    const {image, imageBack, title, profil, onClick, people, altPeopleImage} = this.props;
    return (
      <div className={"homeHeaderGlobalFlexItem "+profil } data-profil={profil} onClick={onClick}  >
          <img src={image} className="homeHeaderGlobalFlexItemBackgroundDesktop"/>
          <h2 className="homeHeaderGlobalFlexItemTitle">{title}</h2>
          <img src={people} className={'homeHeaderGlobalFlexItemPeopleDesktop'} alt={altPeopleImage}/>
          <img src={imageBack} className="homeHeaderGlobalFlexItemBackgroundMobile"/>
      </div>
    );
  }
}

HomeHeaderGlobalFlexItem.propTypes = {
  image: PropTypes.string.isRequired,
  people: PropTypes.string,
  altPeopleImage: PropTypes.string,
  imageBack: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  profil: PropTypes.string.isRequired,
  onClick: PropTypes.func
};


export default HomeHeaderGlobalFlexItem;
