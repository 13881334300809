import Layout from "../components/layout"
import Blocks from "./blocks/blocks"
import React, { useEffect, useRef, useState } from "react"
import HomeHeader from "../components/homeHeader"
import TwoButtonQuotation from "./blocks/twoButtonQuotation"
import TwoLinksBar from "../components/twoLinksBar"
import ReactDOM from "react-dom"
import UrlService from "../services/global/urlService"


const TemplateHome = ({ pageContext: context }) => {
  const { page } = context
  const { media } = context
  const titleSeo = page.titleSEO || ""
  const keywords = page.keywords ? page.keywords.split() : []
  const { canonicalUrl, tracker } = page
  const canonical = UrlService.addSlashToEndOfUrl(canonicalUrl)
  const bodyHome = useRef()
  const [position, setPosition] = useState(220)
  useEffect(() => {
    window.addEventListener("scroll", getPosition, { passive: true })
  }, [])
  function getPosition() {
    // pb sous chrome
    let top = ReactDOM.findDOMNode(bodyHome.current) ? ReactDOM.findDOMNode(bodyHome.current).getBoundingClientRect().top:0
    setPosition(top)
  }

  return (
    <Layout
      titleSeo={titleSeo}
      keywordsSeo={keywords}
      description={page.description}
      tracker={tracker}
      canonical={canonical}
      position={position}
      home={true}
    >
      <HomeHeader ref={bodyHome} />
      <div id="blocs" className={'homePage'}>
        <TwoButtonQuotation />
        <TwoLinksBar />
        <Blocks data={page.blocks} media={media} />
      </div>
    </Layout>
  )
}

export default TemplateHome
