import React, { Component } from "react"
import HeaderCheminotBack from "../images/headerCheminotBackground.png"
import HeaderParticulierBack from "../images/headerParticulierBackground.png"
import HeaderAgentTerritorialBack from "../images/headerAgentTerritorialBackground.png"
import HeaderIndeBack from "../images/headerIndependantBackground.png"
import IconButton from "../images/iconButton.png"
import HomeHeaderGlobalFlexItem from "./homeHeaderGlobalFlexItem"
import  BackgroundCheminot from "../images/background-cheminot.jpg"
import  BackgroundTer from "../images/background-ter.jpg"
import  BackgroundEntreprise from "../images/background-company.jpg"
import  BackgroundParticulier from "../images/background-particulier.jpg"
import  PeopleCheminot from "../images/people-cheminot.png"
import  PeopleTer from "../images/people-agent.png"
import  PeopleEntreprise from "../images/people-tns2023.png"
import  PeopleParticulier from "../images/people-particulier.png"
import classNames from "classnames"
import "./homeHeader.css"
import { ATInternetTagService } from './../services/global/ATInternetService';
import { isMobile, deviceDetect } from "react-device-detect"

class HomeHeader extends Component {
  onClickCheminot() {
    const heightHomeHeaderGlobalFlex = document.getElementsByClassName(
      "homeHeaderGlobalFlex"
    )[0].clientHeight
    this.setState({
      hideCheminotChoice: false,
      hideParticulierChoice: true,
      hideAgentChoice: true,
      hideIndeChoice: true,
      hideHomeHeaderGlobalFlex: true,
      heightHomeHeaderGlobalFlex: heightHomeHeaderGlobalFlex,
    })
  }
  onClickParticulier() {
    const heightHomeHeaderGlobalFlex = document.getElementsByClassName(
      "homeHeaderGlobalFlex"
    )[0].clientHeight
    this.setState({
      hideCheminotChoice: true,
      hideParticulierChoice: false,
      hideAgentChoice: true,
      hideIndeChoice: true,
      hideHomeHeaderGlobalFlex: true,
      heightHomeHeaderGlobalFlex: heightHomeHeaderGlobalFlex,
    })
  }
  onClickAgent() {
    const heightHomeHeaderGlobalFlex = document.getElementsByClassName(
      "homeHeaderGlobalFlex"
    )[0].clientHeight
    this.setState({
      hideCheminotChoice: true,
      hideParticulierChoice: true,
      hideAgentChoice: false,
      hideIndeChoice: true,
      hideHomeHeaderGlobalFlex: true,
      heightHomeHeaderGlobalFlex: heightHomeHeaderGlobalFlex,
    })
  }
  onClickIndependant() {
    const heightHomeHeaderGlobalFlex = document.getElementsByClassName(
      "homeHeaderGlobalFlex"
    )[0].clientHeight
    this.setState({
      hideCheminotChoice: true,
      hideParticulierChoice: true,
      hideAgentChoice: true,
      hideIndeChoice: false,
      hideHomeHeaderGlobalFlex: true,
      heightHomeHeaderGlobalFlex: heightHomeHeaderGlobalFlex,
    })
  }
  onClickHeaderChoiceClose() {
    this.setState({
      hideCheminotChoice: true,
      hideAgentChoice: true,
      hideParticulierChoice: true,
      hideIndeChoice: true,
      hideHomeHeaderGlobalFlex: false,
    })
  }

  constructor() {
    super()
    this.state = {
      hideCheminotChoice: true,
      hideParticulierChoice: true,
      hideAgentChoice: true,
      hideIndeChoice: true,
      hideHomeHeaderGlobalFlex: false,
      heightHomeHeaderGlobalFlex: 10,
    }
    this.onClickCheminot = this.onClickCheminot.bind(this)
    this.onClickHeaderChoiceClose = this.onClickHeaderChoiceClose.bind(this)
    this.onClickParticulier = this.onClickParticulier.bind(this)
    this.onClickAgent = this.onClickAgent.bind(this)
    this.onClickIndependant = this.onClickIndependant.bind(this)
  }

  componentDidMount() {
    const heightHomeHeaderGlobalFlex = document.getElementsByClassName(
      "homeHeaderGlobalFlex"
    )[0].clientHeight
    this.setState({ heightHomeHeaderGlobalFlex: heightHomeHeaderGlobalFlex })
    // On met ça ici parce que dans la template de la homepage, ça marche pas ???
    ATInternetTagService.sendPage({page: 'homepage',page_chapter1:'home'})
  }

  render() {

    const homeHeaderGlobalFlexClassNames = classNames("homeHeaderGlobalFlex",{
      "none" : this.state.hideHomeHeaderGlobalFlex,
      "flex" : !this.state.hideHomeHeaderGlobalFlex
      })

    const homeHeaderChoiceCheminotClassNames = classNames("homeHeaderChoice cheminot",{
      "none" : this.state.hideCheminotChoice,
      "flex" : !this.state.hideCheminotChoice
    })

    const homeHeaderChoiceParticulierClassNames = classNames("homeHeaderChoice particulier",{
      "none" : this.state.hideParticulierChoice,
      "flex" : !this.state.hideParticulierChoice
    })

    const homeHeaderChoiceAgentClassNames = classNames("homeHeaderChoice agent",{
      "none" : this.state.hideAgentChoice,
      "flex" : !this.state.hideAgentChoice
    })

    const homeHeaderChoiceIndeClassNames = classNames("homeHeaderChoice independant",{
      "none" : this.state.hideIndeChoice,
      "flex" : !this.state.hideIndeChoice
    })

    return (
      <section className="homeHeader">
        <div className="homeHeaderGlobal">
          <div
            className={homeHeaderGlobalFlexClassNames}
          >
            <HomeHeaderGlobalFlexItem
              image={BackgroundCheminot}
              people={PeopleCheminot}
              altPeopleImage={"Cheminot"}
              imageBack={HeaderCheminotBack}
              title="Cheminot"
              profil="cheminot"
              onClick={this.onClickCheminot}
            />
            <HomeHeaderGlobalFlexItem
                image={BackgroundParticulier}
                people={PeopleParticulier}
                altPeopleImage={"Particulier"}
              imageBack={HeaderParticulierBack}
              title="Particulier"
              profil="particulier"
              onClick={this.onClickParticulier}
            />
            <HomeHeaderGlobalFlexItem
                image={BackgroundTer}
                people={PeopleTer}
                altPeopleImage={"Agent territorial"}
              imageBack={HeaderAgentTerritorialBack}
              title="Agent territorial / Collectivité"
              profil="agent"
              onClick={this.onClickAgent}
            />
            <HomeHeaderGlobalFlexItem
                image={BackgroundEntreprise}
                people={PeopleEntreprise}
                altPeopleImage={"Entreprise"}
              imageBack={HeaderIndeBack}
              title="Indépendant / Entreprise"
              profil="independant"
              onClick={this.onClickIndependant}
            />
          </div>
        </div>
        <div
          className={homeHeaderChoiceCheminotClassNames}
          style={{
            height: `${this.state.heightHomeHeaderGlobalFlex}px`,
          }}
        >
          <div className="container-close-icon">
            <i
              className="fa fa-times-circle fa-2x homeHeaderChoiceClose"
              onClick={this.onClickHeaderChoiceClose}
            />
          </div>
          <div className="homeHeaderChoiceContent">
            <p className="homeHeaderChoiceTitle">
              Veuillez choisir votre profil ci-dessous :
            </p>
            <div className="homeHeaderChoiceFlex">
              <a className="buttonProfil" href="/cheminot-en-activite/" onClick={() => ATInternetTagService.sendNavClick({click:'cheminot-en-activite'})}>
                Cheminot en activité
                <img src={IconButton} className="buttonProfilImage" alt="" />
              </a>
              <a className="buttonProfil" href="/cheminot-retraite/" onClick={() => ATInternetTagService.sendNavClick({click:'cheminot-retraite'})}>
                Cheminot retraité
                <img src={IconButton} className="buttonProfilImage" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div
          className={homeHeaderChoiceParticulierClassNames}
          style={{
            height: `${this.state.heightHomeHeaderGlobalFlex}px`,
          }}
        >
          <div className="container-close-icon">
            <i
              className="fa fa-times-circle fa-2x homeHeaderChoiceClose"
              onClick={this.onClickHeaderChoiceClose}
            />
          </div>
          <div className="homeHeaderChoiceContent">
            <p className="homeHeaderChoiceTitle">
              Veuillez choisir votre profil ci-dessous :
            </p>
            <div className="homeHeaderChoiceFlex">
              <a className="buttonProfil" href="/particulier/" onClick={() => ATInternetTagService.sendNavClick({click:'particulier'})}>
                Particulier de moins de 60 ans
                <img src={IconButton} className="buttonProfilImage" alt="" />
              </a>
              <a className="buttonProfil" href="/senior/" onClick={() => ATInternetTagService.sendNavClick({click:'senior'})}>
                Particulier de 60 ans et plus
                <img src={IconButton} className="buttonProfilImage" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div
          className={homeHeaderChoiceAgentClassNames}
          style={{
            height: `${this.state.heightHomeHeaderGlobalFlex}px`,
          }}
        >
          <div className="container-close-icon">
            <i
              className="fa fa-times-circle fa-2x homeHeaderChoiceClose"
              onClick={this.onClickHeaderChoiceClose}
            />
          </div>
          <div className="homeHeaderChoiceContent">
            <p className="homeHeaderChoiceTitle">
              Veuillez choisir votre profil ci-dessous :
            </p>
            <div className="homeHeaderChoiceFlex">
              <a className="buttonProfil" href="/agent-territorial/" onClick={() => ATInternetTagService.sendNavClick({click:'agent_territorial'})}>
                Agent territorial
                <img src={IconButton} className="buttonProfilImage" alt="" />
              </a>
              <a className="buttonProfil" href="/collectivite-territoriale/" onClick={() => ATInternetTagService.sendNavClick({click:'collectivite_territoriale'})}>
                Collectivité, je souhaite protéger mes salariés
                <img src={IconButton} className="buttonProfilImage" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div
          className={homeHeaderChoiceIndeClassNames}
          style={{
            height: `${this.state.heightHomeHeaderGlobalFlex}px`,
          }}
        >
          <div className="container-close-icon">
            <i
              className="fa fa-times-circle fa-2x homeHeaderChoiceClose"
              onClick={this.onClickHeaderChoiceClose}
            />
          </div>
          <div className="homeHeaderChoiceContent">
            <p className="homeHeaderChoiceTitle">
              Veuillez choisir votre profil ci-dessous :
            </p>
            <div className="homeHeaderChoiceFlex">
              <a className="buttonProfil buttonProfil370" href="/professionnel-independant/" onClick={() => ATInternetTagService.sendNavClick({click:'professionnel_independant'})}>
                Indépendant
                <img src={IconButton} className="buttonProfilImage" alt="" />
              </a>
              <a className="buttonProfil buttonProfil370" href="/entreprise-syntec/" onClick={() => ATInternetTagService.sendNavClick({click:'entreprise'})}>
                Entreprise - CCN SYNTEC IDCC 1486
                <img src={IconButton} className="buttonProfilImage" alt="" />
              </a>
            </div>
            <div>
              <br></br>
            </div>
            <div className="homeHeaderChoiceFlex">
             <a className="buttonProfil buttonProfil370" href="/transport-routier/" onClick={() => ATInternetTagService.sendNavClick({click:'entreprise_syntec'})}>
                Entreprise - CCN transport routier IDCC 16
                <img src={IconButton} className="buttonProfilImage" alt="" />
              </a> 
              <a className="buttonProfil buttonProfil370" href="/entreprise/" onClick={() => ATInternetTagService.sendNavClick({click:'entreprise_syntec'})}>
                Entreprise - Autres CCN
                <img src={IconButton} className="buttonProfilImage" alt="" />
              </a> 
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default HomeHeader
